import React from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";

type Props = {
  location: Location;
  children: React.ReactNode;
};

const Layout = ({ children, location }: Props) => {
  return (
    <>
      <Helmet>
        <html lang="de" />
      </Helmet>
      <div>
        <Header location={location}>
          <div className="py-32">hello world</div>
        </Header>
        <main className="container px-2 mx-auto pt-12">{children}</main>
        <footer className="bg-brand py-12 mt-16 text-white">
          <div className="container px-2 mx-auto">
            © {new Date().getFullYear()} Club of Code
          </div>
        </footer>
      </div>
    </>
  );
};

export default Layout;
