import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import Particles, { ISourceOptions } from "react-tsparticles";

type Props = {
  location: Location;
  data: any;
  children?: React.ReactNode;
};

type Route = {
  title: string;
  to: string;
  isExternal?: boolean;
};

const particlesConfig: ISourceOptions = {
  fpsLimit: 60,
  interactivity: {
    detectsOn: "canvas",

    modes: {
      bubble: {
        distance: 200,
        duration: 0.5,
        opacity: 0.8,
        size: 40
      },
      push: {
        quantity: 4
      },
      repulse: {
        distance: 200,
        duration: 0.4
      }
    }
  },
  particles: {
    color: {
      value: "#ffffff"
    },
    links: {
      color: "#ffffff",
      distance: 150,
      enable: true,
      opacity: 0.25,
      width: 1
    },
    move: {
      direction: "none",
      enable: true,
      outMode: "bounce",
      random: false,
      speed: 1,
      straight: false
    },
    number: {
      density: {
        enable: true,
        value_area: 800
      },
      value: 80
    },
    opacity: {
      value: 0.15
    },
    shape: {
      type: "circle"
    },
    size: {
      random: true,
      value: 4
    }
  },
  detectRetina: true
};

const routes: Route[] = [
  { title: "Startseite", to: "/" },
  { title: "Club of Code", to: "https://clubofcode.io", isExternal: true }
];

const linkCss = "hover:text-white text-white-secondary";

const Header = ({ data, location }: Props) => {
  const post = data?.allGhostPost?.edges?.[0]?.node;
  const isHome = location?.pathname === "/";

  return (
    <header className="relative bg-gradient-to-tl from-brand to-black pb-8">
      <div className="relative z-10">
        <div className="container px-2 mx-auto text-center border-b border-white-divide pb-0 pt-4 md:pb-2 md:pt-6">
          <h1 className="font-ci text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white tracking-wider sm:my-1 md:my-2">
            <Link to="/">Club of Code Blog</Link>
          </h1>
        </div>
        <nav className="container px-2 mx-auto text-center space-x-6 my-2 md:my-4">
          {routes.map((r) =>
            r.isExternal ? (
              <a href={r.to} className={linkCss} target="_blank">
                {r.title}
              </a>
            ) : (
              <Link to={r.to} className={linkCss}>
                {r.title}
              </Link>
            )
          )}
        </nav>
      </div>
      {isHome && post && (
        <>
          <div
            className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-transparent to-black"
            style={{ zIndex: 5 }}
          />
          <Particles
            id="tsparticles"
            options={particlesConfig}
            className="hidden sm:block absolute top-0 left-0 z-1 w-full h-full"
          />
          <article className="container px-2 relative z-10 mx-auto text-white py-6">
            <span className="text-accent font-bold uppercase text-xs tracking-wider">
              featured
            </span>
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-2 tracking-wide">
              <Link to={`/${post.slug}`} className="hover:underline">
                {post?.title}
              </Link>
            </h1>
            <p className="text-gray-300 w-full md:w-2/3 lg:w-1/2 mb-2 sm:text-lg">
              {post.excerpt}
            </p>
          </article>
        </>
      )}
    </header>
  );
};

const HeaderWithQuery = (props: Omit<Props, "data">) => (
  <StaticQuery
    query={graphql`
      query GhostFeaturedPost {
        allGhostPost(
          sort: { order: DESC, fields: [published_at] }
          limit: 1
          filter: { featured: { eq: true } }
        ) {
          edges {
            node {
              ...GhostPostFields
            }
          }
        }
      }
    `}
    render={(data) => <Header data={data} {...props} />}
  />
);

export default HeaderWithQuery;
